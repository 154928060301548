<template>
  <div class="content-wrapper">
    <div class="top">
      <div class="chef"></div>
      <div class="title">
        Merhaba, Ben Şef! <br />
        Bugün sana nasıl yardımcı olabilirim?
      </div>
    </div>
    <iframe :src="`${setIframeSrc()}`" scrolling="no" style="border: 0; padding-bottom: 15px" />
  </div>
</template>

<script>
export default {
  name: 'Chatbot',
  methods: {
    setIframeSrc() {
      const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
      const isDevOrQaEnvironment = apiBaseUrl.includes('qa') || apiBaseUrl.includes('dev');

      const environmentPrefix = isDevOrQaEnvironment
        ? apiBaseUrl.includes('qa')
          ? 'qa'
          : 'dev'
        : 'pmaktif';

      const iframeSrc = isDevOrQaEnvironment
        ? `https://${environmentPrefix}.pmaktiftr-${environmentPrefix}.aws.pmicloud.biz/chatbot.html`
        : `https://${environmentPrefix}.com/chatbot.html`;

      return iframeSrc;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
}
iframe {
  width: 100%;
  height: 100%;
  flex: 1;
}
.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .chef {
    height: 60px;
    width: 60px;
    background-image: url('~@/assets/images/kulaklık-chatbot-buyuk.png');
    background-position: top center;
    background-size: 100%;
    border-radius: 50%;
  }
  .title {
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px;
    text-align: center;
  }
}
</style>
